import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
  AuthCodePKCEService,
  AuthenticationService,
  CVSAlertModule,
  CVSBaseModule,
  CVSLoaderModule
} from "angular-component-library";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HomePageComponent} from './home-page/home-page.component';
import {DashboardPageComponent} from './dashboard-page/dashboard-page.component';
import {MatIconModule} from "@angular/material/icon";
import {FeedbackDialogComponent} from './feedback-dialog/feedback-dialog.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from "@angular/material/dialog";
import {DialogAlertPlaceholderComponent} from "./shared/cvs-custom-placeholder/cvs-custom-placeholder.component";
import {
  MaintenanceAlertPlaceholderComponent
} from "./shared/cvs-custom-placeholder/maintenance-alert-placeholder.component";
import {
  NotificationAlertPlaceholderComponent
} from "./shared/cvs-custom-placeholder/notification-alert-placeholder.component";
import {OtherAlertPlaceholderComponent} from "./shared/cvs-custom-placeholder/other-alert-placeholder.component";
import { UsefulLinksComponent } from './useful-links/useful-links.component';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import { register } from 'swiper/element/bundle';
import { PagesLayoutComponent } from './pages-layout/pages-layout.component';
register();

import {EnvironmentLoaderService} from "../config/environment-loader.service";
import {InitialPageComponent} from "./initial-page/initial-page.component";

const initAppFn = (envService: EnvironmentLoaderService) => {
    console.log('location.hostname '+ location.hostname)
    if(location.hostname.includes("dev")){
        return () => envService.loadEnvConfig('/assets/config/app-config-dev.json');
    }else if(location.hostname.includes("uat")){
        console.log('loading uat configuration ')
        return () => envService.loadEnvConfig('/assets/config/app-config-uat.json');
    }else if(location.hostname.includes("stg")){
        return () => envService.loadEnvConfig('/assets/config/app-config-stg.json');
    }else if(location.hostname === "acranalytics.cvshealth.com"){
        return () => envService.loadEnvConfig('/assets/config/app-config-prod.json');
    }
    return () => envService.loadEnvConfig('/assets/config/app-config.json');
};

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    DashboardPageComponent,
    FeedbackDialogComponent,
    DialogAlertPlaceholderComponent,
    MaintenanceAlertPlaceholderComponent,
    NotificationAlertPlaceholderComponent,
    OtherAlertPlaceholderComponent,
    UsefulLinksComponent,
    PagesLayoutComponent,
      InitialPageComponent


  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CVSBaseModule,
        CVSLoaderModule,
        CVSAlertModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FormsModule,
    ],
  providers: [
      EnvironmentLoaderService,
      {
          provide: APP_INITIALIZER,
          useFactory: initAppFn,
          multi: true,
          deps: [EnvironmentLoaderService],
      },
    {provide: AuthenticationService, useClass: AuthCodePKCEService},
  ],
  bootstrap: [InitialPageComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
