import {Component, OnInit, ViewEncapsulation} from "@angular/core";


@Component({
    selector: 'initial-page',
    templateUrl: './initial-page.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./initial-page.component.scss']
})

export class InitialPageComponent implements OnInit{
    public displayApp: boolean | undefined;
    public displayInitPage: boolean | undefined;
    public login_email: any;
    public isEmailValid: boolean = true;
    storeUserDomain() {
        if(this.login_email && this.isEmailValid){
            sessionStorage.setItem('login_email',this.login_email)
            this.displayApp=true;
        }else{
            this.isEmailValid = false
        }

    }

    canRenderInitialPageComponent(){
        return this.displayInitPage;
    }

    canRenderAppComponent(){
        return (this.displayApp || sessionStorage.getItem('PKCE_verifier'));
    }


    ngOnInit(): void {
       setInterval(() => {
           this.displayInitPage =  (sessionStorage.getItem('PKCE_verifier') == null);
        }, 2000);
    }

    validateEmail($event: Event) {
        this.isEmailValid = this.login_email.length > 0 ? /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(this.login_email): true;

    }
}