<cvs-base [baseConfig]="baseConfig" [authConfig]="authConfig"  [sideNavConfig]="sideNavConfig">
    <table class="mainTable">
      <tr>
        <cvs-alert-placeholder></cvs-alert-placeholder></tr>
      <tr class="feedbackBtn">
        <button class="cvs-btn-ghost" (click)="addFeedback()">
          <mat-icon svgIcon="conversation--s"></mat-icon>
          Feedback
        </button>
      </tr>
    </table>
    <router-outlet></router-outlet>
  </cvs-base>

